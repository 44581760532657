<template>
  <div style="text-align: justify">
    <h2 class="mb-4">Solubility Product - Prelab Exercise</h2>

    <p class="mb-2">
      Please enter your exercise number below. Without this number, you will automatically receive a
      grade of 0 on your prelab. If this does not match the number on the prelab you hand in, you
      will receive a grade of 0.
    </p>

    <calculation-input
      v-model="inputs.exerciseNumber"
      class="mb-5"
      prepend-text="$\text{Exercise Number:}$"
      :disabled="!allowEditing"
    />

    <p class="mb-5">
      a) Write out answers for Parts i) and ii), then take a picture, and upload it in a single
      image file below.
    </p>

    <p class="mb-3 pl-8">
      i) Write the equilibrium expression for the dissolution of solid lead(II) iodide,
      <chemical-latex content="PbI2," /> in water.
    </p>

    <p class="mb-5 pl-8">
      ii) Write the equation relating the solubility product,
      <stemble-latex content="$\text{K}_\text{sp},$" /> for <chemical-latex content="PbI2" /> to the
      solubility, <stemble-latex content="$\text{S,}$" /> of the compound.
    </p>

    <file-upload-ui-component
      style="width: 100%"
      label="Click here to upload a file"
      :max-number-of-files="1"
      :maxFileSize="20"
      accepted-file-extensions=".png, .webp, .jpg, .jpeg"
      @upload:attachments="attachments = $event"
    />

    <p class="mb-3" v-if="exNumber >= 101 && exNumber <= 900">
      b) <latex-number number="50.00" unit="\text{mL}" />
      of a standard solution of lead(II) iodide in water was passed through an ion-exchange resin
      (in the acid form). The protons released were collected and titrated against a standard
      solution of
      <latex-number :number="concNaOH.toFixed(6)" unit="\text{M NaOH.}" />
      The endpoint of the titration occurred after the addition of
      <latex-number :number="volNaOH" unit="\text{mL}" />
      of
      <chemical-latex content="NaOH\text{.}" />
    </p>

    <p class="mb-3" v-else>
      b) <span style="font-weight: bold; color: #c50000">Please enter a Valid Exercise Number</span>
    </p>

    <p class="mb-3">
      i) Calculate the molar solubility, <stemble-latex content="$\text{S,}$" /> of
      <chemical-latex content="PbI2\text{.}" />
    </p>

    <calculation-input
      v-model="inputs.molarSolubility"
      class="mb-5"
      prepend-text="$\text{S:}$"
      append-text="$\text{M}$"
      :disabled="!allowEditing"
    />

    <p class="mb-3">
      ii) The value of the solubility product,
      <stemble-latex content="$\text{K}_{\text{sp}},$" /> for lead(II) iodide.
    </p>

    <calculation-input
      v-model="inputs.kspValue"
      prepend-text="$\text{K}_\text{sp}:$"
      :disabled="!allowEditing"
    />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import NumberValue from '../NumberValue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import FileUploadUiComponent from '@/tasks/components/task-ui-components/FileUploadUiComponent.vue';
import {seededRandomBetween} from '@/courses/utils/seededShuffle';

export default {
  name: 'UPEIKspPreLab2V2',
  components: {
    FileUploadUiComponent,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
    LatexNumber,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        exerciseNumber: null,
        molarSolubility: null,
        kspValue: null,
      },
      attachments: [],
    };
  },
  computed: {
    exNumber() {
      if (this.inputs.exerciseNumber) {
        return this.inputs.exerciseNumber;
      } else {
        return 0;
      }
    },
    scaleValue() {
      return seededRandomBetween(this.exNumber, 1.36e-8, 1.42e-8, 11);
    },
    volNaOH() {
      return seededRandomBetween(this.exNumber * 2, 17, 25, 2);
    },
    concNaOH() {
      return (Math.pow(this.scaleValue / 4, 1 / 3) * 0.05 * 2) / (this.volNaOH / 1000);
    },
  },
};
</script>
